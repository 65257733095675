import React, { useState } from 'react';
import cls from 'classnames';
import { Parallax } from 'rc-scroll-anim';

import styles from './style.module.less';

export default ({ title, content, bgColor = 'light', src }) => {
  const [percent, setPercent] = useState(0);

  return (
    <div id="student-gallery" className={cls([styles.container, bgColor])}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <h2 className={styles.infoTitle}>{title}</h2>
          {(content || []).map((c, i) => (
            <p key={i}>{c}</p>
          ))}
        </div>
        <Parallax animation={{ onUpdate: (p) => setPercent(p) }} />
        <div className={styles.cover} style={{ top: 80 - 100 * percent }}>
          <div></div>
          <img src={src} />
        </div>
      </div>
    </div>
  );
};
