import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from 'components/Header';
import Footer from 'components/Footer';
import HeadCarousel from 'components/HeadCarousel';
import ScaleCarousel from 'components/ScaleCarousel';
import AccCarousel from 'components/AccCarousel';
import StepCarousel from 'components/StepCarousel';
import CardList from 'components/CardList';
import StudentGallery from 'components/StudentGallery';
import TeachEnv from 'components/TeachEnv';
import BookFrom from 'components/BookForm';
import FixedBtn from 'components/FixedBtn';
import scrollTo from 'utils/scrollTo';
import useCR from 'utils/useCR';
import track from 'utils/track';

import styles from './adult.module.less';

export default ({ location: { search } }) => {
  const cr = useCR(search);

  // pv 埋点
  useEffect(() => {
    track().pv('【指教音乐官网】成人钢琴——页面访问');
  }, []);

  if (cr) {
    return (
      <iframe
        style={{ border: 'none' }}
        width={window.innerWidth}
        height={window.innerHeight}
        src="https://www.finger66.com/activity/teacher-join/#/?source=410000"
      />
    );
  }

  return (
    <div>
      <Header instrument="piano" customer="adult" />
      <Helmet>
        <title>Finger-成人零基础钢琴</title>
      </Helmet>
      <HeadCarousel
        list={[
          {
            src: require('images/piano/adult/header-1.jpg'),
            itemStyle: { maxWidth: 1920 },
            content: [
              <h2 key="1" className={styles.tcTitle}>
                零基础成人钢琴课
              </h2>,
              <p key="2" className={styles.tcDesc}>
                兴趣培养、自我提升、免费琴房、自由约课
              </p>,
              <p key="3" className={styles.tcDesc}>
                365天自由约课，你距离钢琴唱作人只有一套课的距离。
              </p>,
              <a
                key="4"
                // eslint-disable-next-line no-script-url
                href="javascript:;"
                className={styles.tcBtn}
                onClick={() => {
                  track().pv('【指教音乐官网】成人钢琴——banner免费预约');
                  scrollTo({ id: 'book-form' });
                }}
              >
                免费预约体验课
              </a>,
            ],
          },
        ]}
      />
      <ScaleCarousel
        title="从演奏到创作"
        description="满足各个阶段水平的要求"
        list={[
          {
            src: require('images/piano/adult/scale-1.jpg'),
            itemStyle: { maxWidth: 600 },
            title: '零基础小班课',
            content: [
              '从零开始学钢琴，学习五线谱、钢琴琴键知识与基础演奏能力。',
              '课程搭配数十首经典作品，让成人快速掌握一定水平的视奏能力与基础乐理知识。',
            ],
            video: 'http://media.finger66.com/zhijiao/zhijiao-ljc-mzdhl.mp4',
          },
          {
            src: require('images/piano/adult/scale-2.jpg'),
            itemStyle: { maxWidth: 600 },
            title: '进阶精英课',
            content: [
              '在基础演奏技巧之上引导学员学习特定的演奏方向，',
              '学员会学习即兴伴奏、流行弹唱、四手联弹等难度较高的内容。',
              '同时掌握更复杂的演奏技巧，综合能力达到较高水平。',
            ],
            video: 'http://media.finger66.com/zhijiao/zhijiao-jpk-lvse.mp4',
          },
          {
            src: require('images/piano/adult/scale-3.jpg'),
            itemStyle: { maxWidth: 600 },
            title: '一对一定制课',
            content: [
              '适合有一定钢琴基础的学员进行定制化学习。',
              '老师会根据学员的学习需求单独安排课程内容。',
            ],
            video: 'http://media.finger66.com/zhijiao/zhijiao-despacito.mp4',
          },
        ]}
      />
      <AccCarousel
        title="课程体系"
        description="美式钢琴教学体系 专业团队匠心研制"
        list={[
          {
            itemStyle: { maxWidth: 1920 },
            src: require('images/piano/adult/acc-1.jpg'),
            title: '01/零基础',
            desc: '共4个阶段',
            steps: [
              {
                label: 'A阶',
                content:
                  '学习音乐中基本乐理知识，掌握基本弹奏技巧，训练双手协调能力，可以达到双手弹奏的程度。',
              },
              {
                label: 'B阶',
                content:
                  '深入学习更多乐理知识，学习掌握和弦伴奏原始形态，学习音阶与琶音弹奏技巧，演奏多首经典名曲。',
              },
              {
                label: 'C阶',
                content:
                  '学习运用各类常见伴奏音型，学习更多大小调音阶琶音演奏技巧，曲目中指法、技巧和节奏难度升级。',
              },
              {
                label: 'D阶',
                content:
                  '演奏曲目更加丰富，涵盖古典、民族、流行、爵士、电影音乐、轻音乐等多种曲风，乐曲的织体更加灵活与复杂。',
              },
            ],
          },
          {
            itemStyle: { maxWidth: 1920 },
            src: require('images/piano/adult/acc-2.jpg'),
            title: '02/精品课',
            desc: '共5个阶段',
            steps: [
              {
                label: 'lv.1',
                content:
                  '【初识即兴伴奏】与成人零基础课程的知识衔接，以理论知识为主，配合对应练习条以及节选乐曲的片段，让学员能够为歌曲编配左手的伴奏和声，以及掌握常用的几个调性变换\n',
              },
              {
                label: 'lv.2',
                content:
                  '【即兴伴奏实战】巩固Level 1的理论知识，以实践演奏为主，掌握流行歌曲中的几种常用伴奏套路。',
              },
              {
                label: 'lv.3',
                content:
                  '【弹唱系列】学习自弹自唱，掌握不同风格的乐曲的伴奏模式。考虑到不同年龄段学员的需求，在这个单元设置了怀旧精曲和流行热曲两个系列，学员可以二选一。',
              },
              {
                label: 'lv.4',
                content:
                  '【名曲精弹】涵盖了新世纪、流行、爵士、音乐剧等多种曲风的经典曲目，让学员掌握不同风格的乐曲的音乐处理，并且学习一些更细节的弹奏技巧和音乐表现力。',
              },
              {
                label: 'lv.5',
                content:
                  '【四手联弹】学习四手联弹的演奏形式，掌握更多演奏技巧，学会在合奏中如何让乐曲的旋律、节奏、和声上达到相互协调统一的演奏效果，培养良好音乐表现力。',
              },
            ],
          },
        ]}
      />
      <StepCarousel
        title="上课流程"
        description="互联网时代下的音乐教育模式"
        list={[
          {
            src: require('images/piano/adult/step-1.jpg'),
            itemStyle: { maxWidth: 1000 },
            step: '01',
            title: '【课前】掌上课程表 随时查询课程信息',
            content: [
              '下载Finger指教APP端 随时查询/预约上课时间',
              '有效期限内可无限重听小班课，直到学会为止',
            ],
          },
          {
            src: require('images/piano/adult/step-2.jpg'),
            itemStyle: { maxWidth: 1000 },
            step: '02',
            title: '【课中】零基础课、精品课、一对一定制课',
            content: [
              '独家研发3类课程，适合不同阶段的成人学员',
              '分阶段学习，轻松掌握即兴演奏与音乐创作能力',
            ],
          },
          {
            src: require('images/piano/adult/step-3.jpg'),
            itemStyle: { maxWidth: 1000 },
            step: '03',
            title: '【课后】附赠琴房练习，学员可到店免费练习',
            content: [
              '成为正式学员，即可享受免费练琴福利',
              '下载APP，自主预约琴房使用时间',
            ],
          },
        ]}
      />
      <CardList
        title="你的担心，其实都不是问题"
        description="更适合成人的学习模式与教学特色"
        list={[
          {
            src: require('images/piano/adult/card-1.jpg'),
            itemStyle: { maxWidth: 400 },
            title: '现在学琴会不会太晚？',
            content: [
              '年龄并不是钢琴学习的硬性门槛。',
              '成人的理解能力更强，能通过作品更快地掌握知识。',
              '在肢体协调能力上，成人的脑部已发育完全，也更具优势。',
            ],
          },
          {
            src: require('images/piano/adult/card-2.jpg'),
            itemStyle: { maxWidth: 400 },
            title: '没有基础，害怕跟不上进度？',
            content: [
              '小班教学能够保证课堂教学质量，老师会手把手进行教学。',
              '同班同学都是学习阶段相近的，课堂内容不会出现过快的情况。',
              '听不懂的课程在有效期限内可无限重听，直到学会为止。',
            ],
          },
          {
            src: require('images/piano/adult/card-3.jpg'),
            itemStyle: { maxWidth: 400 },
            title: '工作忙，没有固定的上课时间？',
            content: [
              '短期出差、外出游玩时可选择冻结课时，回来继续上课，不浪费课程有效期',
              '工作日晚间时段、双休日、节假日课程密集，满足上班族的通勤规则',
            ],
          },
          {
            src: require('images/piano/adult/card-4.jpg'),
            itemStyle: { maxWidth: 400 },
            title: '家里没琴，如何练习？',
            content: [
              '成为正式学员，即可享受免费练琴福利',
              '会员专享购琴低价，品牌电子钢琴任你挑选',
              '日本进口钢琴租借，日租费用低至9.9元',
            ],
          },
        ]}
      />
      <StudentGallery
        title="你的同学"
        content={[
          '4～6人小班教学，遇见更多热爱音乐的同班同学！',
          '龙头企业高管',
          '现代公司白领',
          '高校在读硕士/博士',
          '资深律师、医生、教师',
          '全职妈妈',
        ]}
        src={require('images/piano/adult/student-1.jpg')}
      />
      <TeachEnv
        title="教学环境"
        description="专业设计＆施工团队，打造杭城环境一流的音乐教育机构"
        list={[
          {
            step: '01',
            title: '校区环境',
            desc:
              '各大校区采用统一的现代装修格调，每一个角落都能带给您最佳感官体验。',
            previews: [
              {
                src: require('images/piano/adult/env-1-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
              {
                src: require('images/piano/adult/env-1-2.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
              {
                src: require('images/piano/adult/env-1-3.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '02',
            title: '一对一教室',
            desc:
              '一对一教室采用外国进口原装钢琴，良好的隔音环境打造独立的学习空间。',
            previews: [
              {
                src: require('images/piano/adult/env-2-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
              {
                src: require('images/piano/adult/env-2-2.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '03',
            title: '小班教室',
            desc:
              '小班教室使用全新进口的优质电钢与多功能屏幕进行教学，给学员营造良好的班级学习氛围。',
            previews: [
              {
                src: require('images/piano/adult/env-3-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '04',
            title: '上课教材',
            desc: '独家研发的钢琴课、古典吉他课程、亲子音乐课均配有精美教材。',
            previews: [
              {
                src: require('images/piano/adult/env-4-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
        ]}
      />
      <BookFrom
        title={
          <>
            价值399元的零基础成人钢琴课
            <br />
            首次体验可享免单
          </>
        }
        instrument="piano"
        customer="adult"
        placeholders={{
          schoolId: '选择校区',
          realName: '输入您的姓名',
          mobilePhone: '输入您的手机号',
          verifyCode: '输入您收到的验证码',
        }}
        onDirty={() => track().pv('【指教音乐官网】成人钢琴——填写预约信息')}
        onSubmit={() => track().pv('【指教音乐官网】成人钢琴——成功提交信息')}
      />
      <FixedBtn
        onClick={() => track().pv('【指教音乐官网】成人钢琴——底部免费预约')}
      />
      <Footer showCopyRight={cr} />
    </div>
  );
};
