import React, { useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import cls from 'classnames';

import styles from './style.module.less';

export default ({ list, index }) => {
  const [curr, setCurrent] = useState(index || 0);
  const len = (list || []).length;
  list = list || [];

  return (
    <div id="head-carousel" className={styles.container}>
      <div
        className={styles.slides}
        style={{ transform: `translateX(${-100 * curr}%)` }}
      >
        {(list || []).map(({ src, itemStyle, content }, i) => {
          const slideStyles = src
            ? { backgroundImage: `url(${src})`, backgroundSize: 'cover' }
            : {};

          return (
            <div key={content} className={styles.slide} style={slideStyles}>
              {src && (
                <img
                  className={styles.slideCover}
                  src={src}
                  style={itemStyle}
                />
              )}
              <div className={styles.slideMask} />
              <div className={styles.slideContent}>
                <QueueAnim type="bottom" delay={300}>
                  {i === curr ? content || [] : []}
                </QueueAnim>
              </div>
            </div>
          );
        })}
      </div>

      {len > 1 ? (
        <span
          className={cls([styles.btn, styles.leftBtn])}
          onClick={() => setCurrent((curr + len - 1) % len)}
        >
          <img
            style={{ width: 70 }}
            src={require('images/icon-slider-left.png')}
          />
        </span>
      ) : null}
      {len > 1 ? (
        <span
          className={cls([styles.btn, styles.rightBtn])}
          onClick={() => setCurrent((curr + 1) % len)}
        >
          <img
            style={{ width: 70 }}
            src={require('images/icon-slider-right.png')}
          />
        </span>
      ) : null}
    </div>
  );
};
