import React from 'react';
import cls from 'classnames';

import useLoop from 'utils/useLoop';

import styles from './style.module.less';

export default ({ title, description, bgColor = 'light', list }) => {
  const len = (list || []).length;
  const [curr, { goto, play, pause }] = useLoop(0, { totalSize: len });

  return (
    <div id="step-carousel" className={cls([styles.container, bgColor])}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>
        <div className={styles.steps}>
          <div
            className={styles.stepCovers}
            style={{ transform: `translateY(${-100 * curr}%)` }}
          >
            {(list || []).map(({ src, itemStyle }, i) => (
              <img
                key={'img' + i}
                src={src}
                style={itemStyle}
                className={styles.stepCover}
              />
            ))}
          </div>
          <div className={styles.stepContentsWrapper}>
            <div
              className={styles.stepContents}
              style={{ transform: `translateX(${-100 * curr}%)` }}
            >
              {(list || []).map(({ step, title, content }, i) => {
                return (
                  <div
                    key={step}
                    className={cls([
                      styles.stepContent,
                      i === curr && 'active',
                    ])}
                  >
                    <div className={styles.stepNum}>{step}</div>
                    <h3 className={styles.stepCTitle}>{title}</h3>
                    {content && content.length ? (
                      <ul className={styles.stepCContent}>
                        {content.map((x) => (
                          <li key={x}>{x}</li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className={styles.stepDots}>
              {(list || []).map((_, i) => (
                <span
                  key={i}
                  className={cls([styles.stepDot, i === curr && 'active'])}
                  onMouseEnter={() => {
                    pause();
                    goto(i);
                  }}
                  onMouseLeave={() => play()}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
